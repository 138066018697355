// 获取bim路网数据接口
const dataListURL = `/gateway/hc-space/bimRoad/dataList`;
// 通过路网点名称获取bim路网数据接口
const dataListByNameURL = `/gateway/hc-space/bimRoad/dataListByName`;
// 导航接口步骤
const stepURL = `https://project.zhuneng.cn/d3mapi/maze/step`;

//新的演示接口
const bimRoadUrl = `/gateway/hc-space/hc-space/bimRoad/dataList`;
export { bimRoadUrl, dataListURL, dataListByNameURL, stepURL };
